.container {
  background-color: #040d1a;
  color: #F5F7FA;
  min-height: 100vh;
  padding: 20px;
}
  
.container h1 {
  text-align: center;
  color: #F5F7FA;
  width: 50%;
  border-bottom: 2px solid #87dcc3;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}