.container {
  background-color: #040d1a;
  color: #F5F7FA;
  min-height: 100vh;
  padding: 20px;
}

.container h1 {
  text-align: center;
  color: #F5F7FA;
  width: 50%;
  border-bottom: 2px solid #87dcc3;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.container p {
  text-align: center;
  color: #e6e6e6;
  width: 50%;
  padding-bottom: 10px;
  margin-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
}

.playerList {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playerItem {
  margin: 10px 0;
  border-bottom: 1px solid #1f242f;
  padding-bottom: 10px;
}

.playerItem span {
  color: #F5F7FA;
  font-size: 16px;
}

.bidForm {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  position: relative;
}

.bidForm input[type="number"] {
  background-color: #1f242f;
  border: 1px solid #25333a;
  color: #F5F7FA;
  padding: 8px;
  width: 200px;
  border-radius: 6px;
}

.bidForm button {
  background-color: #25333a;
  color: #87dcc3;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px; /* Ensure the button size stays consistent */
}

.bidForm button:hover {
  background-color: #1f242f;
}

.bidForm button:disabled {
  background-color: #3a4048;
  color: #5f676f;
  cursor: not-allowed;
}

.playerPic {
  float: left;
  height: 32px;
  width: 32px;
  margin-right: 12px;
  flex: 0 0 32px;
  border-radius: 50%;
  background: center center / cover rgb(245, 247, 250, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  margin-bottom: -10px;
  margin-top: -10px;
}

@keyframes spiral {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spiral {
  animation: spiral 1s infinite linear;
  border: 2px solid #87dcc3;
  border-top-color: #333;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-block;
}

.checkmark {
  display: inline-block;
  width: 32px; /* Increased width for bold effect */
  height: 32px; /* Increased height for bold effect */
  background-color: #87dcc3;
  mask: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M9 16.17l-3.5-3.5-1.41 1.41L9 19 19 9l-1.41-1.41z"/></svg>') center / contain no-repeat;
  position: absolute;
}

/* loading spinner */

.loading_container
{
  width: 100%;
  height: 100vh;
  margin:0;
  padding:0;
  background:#040d1a;
}
.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#87dcc3;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 5px #87dcc3;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #87dcc3;
  border-right:3px solid #87dcc3;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.spin
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.spin:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#87dcc3;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #87dcc3;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}