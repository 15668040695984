.background {
    background-color: #040d1a;
    min-height: 100vh;
}

.button {
    background-color: #040d1a;
    border: solid;
    border-width: 0px;
    border-bottom: 1px solid #040d1a;
    /* border-color: #1f242f; */
    color: #F5F7FA;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: lighter;
    border-radius: 6px;
    margin: .5%;
    padding: 4px 0px;
    width: 11.5%;
    height: 28px;
  }

  .button:not(.activeButton):hover::after {
    opacity: 1;
  }

  .button:not(.activeButton)::after
  {
    content: '';
    position: relative;
    display: inline-block;
    font-weight: bold;
    bottom: 8px; /* Adjust as necessary for the underline position */
    left: 0;
    width: 100%;
    height: 2px; /* Adjust as necessary for the underline thickness */
    background-image: linear-gradient(to right, transparent, #87dcc3, transparent);
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
    z-index: 0;
  }

  .button:active {
    background-color: #1f242f;
    border: solid;
    border-color: #87dcc3;
    color: #F5F7FA;
    box-shadow: inset 14px 14px 8px -9px rgba(0, 0, 0, 0.1);
  }

  .activeButton {
    background-color: #25333a;
    border: solid;
    border-width: 1px;
    border-color: #87dcc3;
    color: #87dcc3;
    /* color: #F5F7FA; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    margin: .5%;
    padding: 4px 0px;
    width: 11.5%;
    height: 28px;
  }

  .tabs {
    display: flex;
    flex-wrap: wrap; /* Allows buttons to wrap */
    justify-content: space-around; /* Evenly spaces the buttons */
    padding: 0 7%; /* Horizontal padding */
    margin: 0 auto; /* Centers the container */
    max-width: 2100px; /* Adjust as needed */
  }

  .team_name {
    margin-top: 50px;
    border-bottom: 2px solid #87dcc3; /* Customize the color and thickness */
    padding-bottom: 10px; /* Adjust the space between text and underline */
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    color: #f5f7fa;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
}

.name_banner, .cap_banner {
  width: calc(50% - 10px);
}

.bottom_spacer {
  height: 60px;
}

.deadCapSpacer {
  height: 24px;
}


  .position {
    font-weight: bold;
    display: block;
    float: left;
    width: 40px;
  }

  .flex_container {
    display: flex;
    justify-content: space-between; /* This might need to be adjusted based on your layout */
    width: 100%; /* Ensure full width to accommodate child elements */
  }

  .left_hand {
    padding-right: 1%;
  }
  
  .shadow_box {
    background-color: #1f242f;
    border-radius: 20px;
    padding: 2% 6% 8% 6%;
    margin-bottom: 8%;
    width:490px; /* Full width by default */
    box-shadow: 14px 14px 8px -9px rgba(0, 0, 0, 0.1);    
  }

  .section_header {
    border-bottom: 1px solid #87dcc3;
    margin-block-start: 0.4em;
    margin-block-end: 0.83em;
  }

  .contactContext {
    float: right;
    text-align: right;
    margin-top: 16px;
  }

  .name {
    display: inline-block;
    width: 210px;
  }

  .contract {
    font-weight: bold;
    display: inline-block;
    width: 50px;
  }

  .type {
    float: right;
    width: 30px;
    padding-right: 12px;
    text-align: right;
  }

  .subPlayerInfo {
    margin-left:  84px;
    font-size: 12px;
    font-style: italic;
    color: #b9babb;
    padding-bottom: 8px;
  }

  .playerPic {
    float: left;
    height: 32px;
    width: 32px;
    margin-right: 12px;
    flex: 0 0 32px;
    border-radius: 50%;
    background: center center / cover rgb(245, 247, 250, .3);
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }

  .name_banner {
    text-align: left;
    width: 70%;
  }

  .cap_banner {
    float: none; /* No need to float when using flexbox */
    text-align: right;
    padding-top: 4px;
    margin-bottom: -20px;
    margin-top: 30px;
    background-color: #015351; /*alt #016F6D */
    border-radius: 8px;
    width: auto;
    padding: 22px 24px 20px 20px;
  }

  .cap_figure {
    font-size: 36pt;
    margin-bottom: -20px;
    margin-top: -12px;
  }

  .loading_container
{
  width: 100%;
  height: 100vh;
  margin:0;
  padding:0;
  background:#040d1a;
}
.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#87dcc3;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 5px #87dcc3;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #87dcc3;
  border-right:3px solid #87dcc3;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.spin
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.spin:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#87dcc3;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #87dcc3;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}