.App {
  text-align: center;
  background-color: #040d1a;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-left: auto; /* Centering */
  margin-right: auto; /* Centering */
}

@keyframes logo-fade-and-grow {
  0% {
    opacity: 0;          /* Start fully transparent */
    transform: scale(0.9);  /* Start slightly smaller */
  }
  100% {
    opacity: 1;          /* End fully opaque */
    transform: scale(1.1);  /* End slightly bigger */
  }
}

.App-logo {
  /* The initial state can be defined here if you like, or it can just rely on the 0% keyframe */
  opacity: 0;
  transform: scale(0.9);
  
  animation: logo-fade-and-grow 3s ease-out forwards;
}


.App-header {
  background-color: #040d1a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #dfe6f4;
}

.App-link {
  color: #55cfb0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* LOGIN MODAL STYLING */

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to ensure it's on top */
}

.login-content {
  background-color: #1f242f;
  width: 20%;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  border-radius: 20px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.form-login {
  width: 100%;
  text-align: center;
  padding-top: 30px;
}

.login-logo {
  margin-top: -40px;
  margin-bottom: -40px;
}

.login-header {
  color: #f5f7fa;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}

.form-field {
  display: block;
  width: 94%;
  background-color: #040d1a;
  color: #f5f7fa;
  border: 1px;
  border-color: #868990;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 8px;
}

.close {
  float: right;
  cursor: pointer;
  color: #f5f7fa;
  margin-right: -20px;
}

.login-submit {
  background-color: #55cfb0;
  border: solid;
  border-width: 1px;
  border-color: #1f242f;
  color: #040d1a;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: lighter;
  border-radius: 6px;
  margin: .5%;
  padding: 8px 12px;
  width: auto;
  height: auto;
}

.login-submit:hover {
  background-color: #9de2cd;
  border: solid;
  border-width: 1px;
  border-color: #1f242f;
  color: #040d1a;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: lighter;
  border-radius: 6px;
  margin: .5%;
  padding: 8px 12px;
  width: auto;
  height: auto;
}

.main-content.blurred {
  filter: blur(2px);
}

.error-message {
  color: #d32f2f;
  margin-top: 20px;
}

.container {
  float: right;
  width: 100%;
  height: 56px;
  background-color: #040d1a;
}

.login {
  padding-right: 50px;
  padding-top: 18px;
  float: right;
  background-color: #040d1a;
}

.button {
  float: right;
  background-color: #040d1a;
  border-width: 0px;
  border-radius: 6px;
  /* border-color: #1f242f; */
  color: #F5F7FA;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: lighter;
  padding: 4px 8px 4px 8px;
}

.button:hover {
  font-weight: bolder;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.button:active {
  background-color: #1f242f;
  background-image: linear-gradient(45deg, #87dcc3 0%, #1f242f 50%, #87dcc3 100%);
  background-size: 200% 200%; /* Make the gradient larger than the button to enable the animation */
  color: #F5F7FA;
  box-shadow: inset 14px 14px 8px -9px rgba(0, 0, 0, 0.1);
  animation: gradientAnimation 0.5s ease forwards; /* Adjust time as needed */
  border: none; /* Remove the border if you're replacing it with a gradient */
}